import React from "react";
import "./Footer.css";

function Footer() {
  return (
    <div id="footer" className="footer-style">
      <div className="styling">
        <span class="animate-border border-black"></span>
        <h3>
          Liên hệ ngay <a href="tel:0903610681">0903610681</a> {"hoặc "}
          <a href="tel:0786610681">0786610681</a> {"Gặp Anh Tấn"}
        </h3>
        <h3>Địa chỉ:</h3>
        <h3>Sân Vận Động Thống Nhất</h3>
        <h3>Số: 30 Nguyễn Kim P.6, Q.10, TP.HCM</h3>
        <span class="animate-border border-black"></span>
      </div>
    </div>
  );
}

export default Footer;
