import React from "react";
import "./Header.css";
import { Link } from "react-scroll";

function Header() {
  return (
    <div className="header">
      <a class="logo">Du Lịch Ngọc Trâm</a>
    </div>
  );
}

export default Header;
